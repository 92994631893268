import { useCallback, useRef, useState, type MutableRefObject } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type {
  CellEditingStoppedEvent,
  CellValueChangedEvent,
  ColDef,
  GridApi,
  PasteEndEvent,
} from '@ag-grid-community/core';
import {
  AgGridReact,
  type CustomCellEditorProps,
  type CustomCellRendererProps,
} from '@ag-grid-community/react';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import '@/store/api/rulesApi/rulesApi.ts';

import { z, ZodSchema } from 'zod';

import { useBookPreTradeDealMutation } from '@/store/api/adjustmentsApi/adjustmentApi.ts';
import {
  wayValues,
  type BookPreTradeDealRequest,
  type PostResponse,
  type TradeWay,
} from '@/store/api/adjustmentsApi/adjustmentApiModels.ts';
import { useAppDispatch } from '@/store/hooks.ts';
import { addSuccessToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { parseTradeWay } from '@/components/adjustments/batch/parseTradeWay.ts';
import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';
import { ToggleButton } from '@/components/common/bootstrap/ToggleButton.tsx';
import { Tooltip } from '@/components/common/bootstrap/Tooltip.tsx';
import { isFetchBaseQueryError } from '@/components/common/utils/isFetchBaseQueryError.ts';
import { ShowIf } from '@/components/common/utils/ShowIf.tsx';
import { processDataFromClipboard } from '@/components/rules/addRulesBatchModal/processDataFromClipboard.ts';
import { getAllRowData } from '@/utils/agGrid/agGrid.ts';
import { logger } from '@/utils/libs/logger.ts';

export type AdjustmentRowData = Partial<
  BookPreTradeDealRequest & {
    // true status means loading in progress
    status: boolean | string | undefined;
  }
>;

type AdjustmentColDef = ColDef<AdjustmentRowData> & {
  context:
    | {
        zodSchema: ZodSchema;
      }
    | undefined;
};

class ApiError {
  constructor(
    public err: any,
    public adjustment: BookPreTradeDealRequest,
  ) {}
}

export const CreateBatchAdjustmentModal = NiceModal.create(() => {
  const dispatch = useAppDispatch();
  const modal = useModal();

  const gridApiRef = useRef<GridApi<AdjustmentRowData> | undefined>();
  const [isInvalidGrid, setIsInvalidGrid] = useState(true);
  const [rowData] = useState<AdjustmentRowData[]>([{}]);

  const [bookPreTrade, { isLoading }] = useBookPreTradeDealMutation();
  const [nbrOfValidAdjustment, setNbrOfValidAdjustment] = useState(0);
  const [nbrOfTotalAdjustment, setNbrOfTotalAdjustment] = useState(0);

  const setGridStatuses = useCallback((newStatus: AdjustmentRowData['status']) => {
    if (!gridApiRef.current) {
      return;
    }

    const allRowData = getAllRowData(gridApiRef.current);
    const newRowData = allRowData.map(row => {
      return {
        ...row,
        status: newStatus,
      };
    });
    gridApiRef.current?.setGridOption('rowData', newRowData);
    gridApiRef.current?.refreshCells();
  }, []);

  async function onConfirm() {
    if (!gridApiRef.current) {
      return;
    }
    setGridStatuses(true);

    const adjustmentsRowData = getAllRowData(gridApiRef.current);
    const responses = await Promise.all(
      adjustmentsRowData.map(async row => {
        const adjustment = row as BookPreTradeDealRequest;
        try {
          return await bookPreTrade(adjustment).unwrap();
        } catch (e) {
          return new ApiError(e, adjustment);
        }
      }),
    );

    const newAdjustmentRowData: AdjustmentRowData[] = [];
    const validUpdates: PostResponse[] = [];

    for (const response of responses) {
      if (response instanceof ApiError) {
        const errorMessage = getAdjustmentErrorMessage(response.err);
        logger.logError('Error while booking in x-one {error_s}', errorMessage);

        newAdjustmentRowData.push({ ...response.adjustment, status: errorMessage });
      } else {
        validUpdates.push(response);
      }
    }

    gridApiRef.current?.setGridOption('rowData', newAdjustmentRowData);
    gridApiRef.current.refreshCells();

    setNbrOfValidAdjustment(validUpdates.length);
    setNbrOfTotalAdjustment(adjustmentsRowData.length);

    const message = `${validUpdates.length === adjustmentsRowData.length ? 'All the adjustments' : `${validUpdates.length} adjustment(s)`} have been successfully booked in X-One`;

    if (validUpdates.length) {
      dispatch(addSuccessToastThunk(message, 'Batch daily adjustment'));
    }
    if (newAdjustmentRowData.length === 0) {
      modal.remove();
      return;
    }
  }

  function onGridChange(
    event:
      | PasteEndEvent<AdjustmentRowData>
      | CellValueChangedEvent<AdjustmentRowData>
      | CellEditingStoppedEvent<AdjustmentRowData>,
  ) {
    setGridStatuses(undefined);
    setNbrOfTotalAdjustment(0);
    setNbrOfValidAdjustment(0);
    setIsInvalidGrid(getIsInvalidGrid(event.api));
  }

  const getIsInvalidGrid = useCallback(
    (gridApi: GridApi<AdjustmentRowData> | undefined): boolean => {
      if (!gridApi) {
        return true;
      }

      const rowData = getAllRowData(gridApi);

      const columns = gridApi.getColumns();
      const columnDefs = columns?.map(column => gridApi.getColumnDef(column) ?? undefined) as
        | (AdjustmentColDef | undefined)[]
        | undefined;

      if (rowData.length === 0) {
        return true;
      }

      return rowData.some(row => {
        const editableRows = columnDefs?.filter(
          (columnDef): columnDef is AdjustmentColDef =>
            columnDef !== undefined && columnDef.field !== 'status',
        );

        return editableRows?.some(columnDef => {
          const field = columnDef.field;
          const rowElement = field !== undefined ? row[field] : undefined;
          return (
            rowElement === undefined || !columnDef.context.zodSchema.safeParse(rowElement).success
          );
        });
      });
    },
    [],
  );

  return (
    <BootstrapModal
      size="lg"
      titleId="Adjustment.AddNewBatchModalTitle"
      style={{ maxWidth: '1150px' }}
      closeOnClickAway={false}
      footer={
        <CancelConfirmFooter
          onConfirm={onConfirm}
          confirmButtonProps={{
            variant: 'primary',
            disabled: isInvalidGrid || isLoading,
            component: 'Book in X-One',
          }}
          removeOnConfirm={false}
        >
          <LoadingComponent isLoading={isLoading} />
          <RecapAdjustment
            nbrOfValidAdjustments={nbrOfValidAdjustment}
            totalAdjustments={nbrOfTotalAdjustment}
          />
        </CancelConfirmFooter>
      }
    >
      <div>
        <p>
          After you copied the content from your external tool (CTRL+C), select an empty cell in the
          table and press CTRL+V to paste the content and see its preview.
        </p>
        <BatchAdjustmentAgGrid
          gridApiRef={gridApiRef}
          rowData={rowData}
          gridDisabled={isLoading}
          onGridChange={onGridChange}
        />
      </div>
    </BootstrapModal>
  );
});

function BatchAdjustmentAgGrid(props: {
  gridApiRef: MutableRefObject<GridApi<AdjustmentRowData> | undefined>;
  rowData: AdjustmentRowData[];
  gridDisabled: boolean;
  onGridChange: (
    event:
      | PasteEndEvent<AdjustmentRowData>
      | CellValueChangedEvent<AdjustmentRowData>
      | CellEditingStoppedEvent<AdjustmentRowData>,
  ) => void;
}) {
  return (
    <AgGridReact<AdjustmentRowData>
      modules={[ClientSideRowModelModule, ClipboardModule, RichSelectModule]}
      className="mt-3 ag-theme-alpine ag-theme-era"
      rowSelection="multiple"
      rowData={props.rowData}
      domLayout="autoHeight"
      gridOptions={{
        stopEditingWhenCellsLoseFocus: true,
        columnDefs: getColDefs(),
        autoSizeStrategy: {
          type: 'fitGridWidth',
        },
        defaultColDef: {
          editable: !props.gridDisabled,
        },
        rowHeight: 35,
        suppressMenuHide: false,
      }}
      onGridReady={e => {
        props.gridApiRef.current = e.api;
      }}
      onCellValueChanged={props.onGridChange}
      onCellEditingStopped={props.onGridChange}
      onPasteEnd={props.onGridChange}
      processDataFromClipboard={processDataFromClipboard}
    />
  );
}

const acceptedTradeWayRegExp = new RegExp('^(buy|sell|b|s)$', 'i');

function getColDefs(): AdjustmentColDef[] {
  return [
    {
      field: 'underlying',
      cellEditor: 'agTextCellEditor',
      context: {
        zodSchema: z.string(),
      },
    },
    {
      field: 'way',
      width: 120,
      cellClass: 'px-0',
      context: {
        zodSchema: z.string().regex(acceptedTradeWayRegExp),
      },
      valueParser: ({ newValue }): TradeWay | undefined => {
        return parseTradeWay(newValue);
      },
      cellEditor: ({ value, onValueChange }: CustomCellEditorProps<AdjustmentRowData>) => {
        return (
          <ToggleButton<TradeWay | undefined>
            activeValue={value}
            values={wayValues}
            onClick={way => onValueChange?.(way)}
          />
        );
      },
      cellRenderer: ({ value, setValue }: CustomCellRendererProps<AdjustmentRowData>) => {
        return (
          <ToggleButton<TradeWay | undefined>
            activeValue={value}
            values={wayValues}
            onClick={way => setValue?.(way)}
          />
        );
      },
    },
    {
      field: 'portfolio',
      width: 220,
      context: {
        zodSchema: z.string(),
      },
    },
    {
      field: 'productQuantity',
      headerName: 'Quantity',
      cellEditor: 'agNumberCellEditor',
      valueParser: ({ newValue }) => {
        return Number(newValue);
      },
      cellClass: ({ value }) => {
        if (isNaN(value)) {
          return 'text-danger';
        }
      },
      context: {
        zodSchema: z.number(),
      },
    },
    {
      field: 'productPrice',
      headerName: 'Price',
      cellEditor: 'agNumberCellEditor',
      valueParser: ({ newValue }) => {
        return Number(newValue);
      },
      cellClass: ({ value }) => {
        if (isNaN(value)) {
          return 'text-danger';
        }
      },
      context: {
        zodSchema: z.number(),
      },
    },
    {
      field: 'status',
      editable: false,
      context: undefined,
      cellRenderer: ({ value }: CustomCellRendererProps<AdjustmentRowData>) => {
        if (value === undefined) {
          return null;
        }

        if (value === true) {
          return <div className="spinner spinner-sm" role="status" />;
        }

        if (typeof value === 'string') {
          return (
            <Tooltip contents={value} placement="right" variant="danger">
              <i className="icon text-danger">warning</i>
            </Tooltip>
          );
        }
      },
    },
  ];
}

function LoadingComponent({ isLoading }: { isLoading: boolean }) {
  return (
    <ShowIf condition={isLoading}>
      <div className="d-flex align-items-center gap-2">
        <div className="spinner spinner-sm" role="status"></div>
        <label className="text-secondary form-label">Booking in progress</label>
      </div>
    </ShowIf>
  );
}

function RecapAdjustment({
  nbrOfValidAdjustments,
  totalAdjustments,
}: {
  nbrOfValidAdjustments: number;
  totalAdjustments: number;
}) {
  return (
    <ShowIf condition={nbrOfValidAdjustments !== 0}>
      <div className="d-flex align-items-center gap-2 text-success">
        <i className="icon">check_circle_outline</i>
        <label className="text-success form-label">
          {nbrOfValidAdjustments} out of {totalAdjustments} adjustments successfully booked
        </label>
      </div>
    </ShowIf>
  );
}

const errorSchema = z.object({
  message: z.string(),
});

function getAdjustmentErrorMessage(error: unknown): string | undefined {
  if (isFetchBaseQueryError(error)) {
    const result = errorSchema.safeParse(error.data);
    if (result.success) {
      return result.data.message;
    }
  }

  return 'Unhandled error occurred';
}
