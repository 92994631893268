import { useEffect, useState } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type {
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  IRowNode,
  MenuItemDef,
  SelectionChangedEvent,
  ValueGetterFunc,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';

import type { AnalyticalStructureRowData } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { expandOrCollapseFactory } from '@/components/gridTable/expandOrCollapseFactory.ts';
import { reducePerimeterKeys } from '@/components/gridTable/tools/reducePerimeterKeys.ts';

interface SelectableGridProps {
  rowData: AnalyticalStructureRowData[];
  selectedKeys: string[];
  onSelectKeys: (keys: string[]) => void;
  groupValueGetter?: ValueGetterFunc<AnalyticalStructureRowData>;
  dataE2e?: string;
}

function selectRowNodes(rootNode: IRowNode, nodesToSelect: IRowNode[]) {
  // Inconsistent results if the rootNode is not unselected first.
  rootNode?.setSelected(false, false, 'api');

  for (let i = 0; i < nodesToSelect.length; i++) {
    const node = nodesToSelect[i];
    if (i === nodesToSelect.length - 1) {
      node.setSelected(true);
    } else {
      node.setSelected(true, false, 'api');
    }
  }
}

function* getAsTree(rootNode: IRowNode): Generator<IRowNode> {
  const nodes = [rootNode];
  while (nodes.length > 0) {
    const node = nodes.pop();
    yield node!;
    nodes.push(...(node?.childrenAfterGroup ?? []));
  }
}

function getNodesToSelect(rootNode: IRowNode, selectedKeys: string[]): IRowNode[] {
  const selectedKeysSet = new Set(selectedKeys);
  const nodesToSelect: IRowNode[] = [];

  for (const node of getAsTree(rootNode)) {
    if (selectedKeysSet.has(getPathOfNode(node))) {
      nodesToSelect.push(node);
    }
  }
  return nodesToSelect;
}

export function SelectableGrid({
  rowData,
  selectedKeys,
  onSelectKeys,
  groupValueGetter,
  dataE2e,
}: SelectableGridProps) {
  const [gridApi, setGridApi] = useState<GridApi<AnalyticalStructureRowData> | undefined>();

  const onGridReady = ({ api }: GridReadyEvent<AnalyticalStructureRowData>) => {
    setGridApi(api);
  };

  useEffect(() => {
    if (gridApi === undefined) {
      return;
    }
    const rootNode = gridApi.getModel().getRow(0);
    if (rootNode === undefined) {
      return;
    }
    const nodesToSelect = getNodesToSelect(rootNode, selectedKeys);
    selectRowNodes(rootNode, nodesToSelect);
  }, [selectedKeys, gridApi, rowData]);

  const handleGridSelectionChanged = (event: SelectionChangedEvent<AnalyticalStructureRowData>) => {
    if (event.source === 'api') {
      return;
    }

    const selectedRowData = event.api.getSelectedRows();
    const selectedPerimeterKeys = selectedRowData.map(getKey);
    const fullPerimeterKeys = rowData.map(getKey);
    const reducedPerimeterKeys = reducePerimeterKeys(fullPerimeterKeys, selectedPerimeterKeys);

    onSelectKeys(reducedPerimeterKeys);
  };
  return (
    <div className="ag-theme-alpine ag-theme-era flex-grow-1 w-100" data-e2e={dataE2e}>
      <AgGridReact<AnalyticalStructureRowData>
        modules={[ClientSideRowModelModule, RowGroupingModule]}
        gridOptions={{
          getContextMenuItems: getContextMenuItems(),
          animateRows: true,
          autoGroupColumnDef: {
            headerName: 'Perimeters',
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              checkbox: true,
            },
            sort: 'asc',
            sortable: true,
            minWidth: 370,
            valueGetter: groupValueGetter,
          },
          onGridReady,
          rowSelection: {
            mode: 'multiRow',
            checkboxes: false,
            headerCheckbox: false,
            enableClickSelection: true,
          },
          groupSelectsChildren: true,
          suppressRowClickSelection: true,
          suppressAggFuncInHeader: true,
          treeData: true,
          getDataPath: rowData => rowData.dataPath,
          getRowId: rowData => rowData.data.dataPath.join('/'),
          groupDefaultExpanded: 1,
        }}
        columnDefs={[]}
        onSelectionChanged={handleGridSelectionChanged}
        rowData={rowData}
      />
    </div>
  );
}

const getKey = ({ GOP, PortFolio, ProfitCenter }: AnalyticalStructureRowData) =>
  `[${ProfitCenter}].[${GOP}].[${PortFolio}]`;

const getPathOfNode = (node: IRowNode): string => {
  return node
    .getRoute()!
    .slice(1) // Remove AllMember
    .map(p => `[${p}]`)
    .join('.');
};

function getContextMenuItems() {
  return (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    if (params.node == null || !params.node.isExpandable()) {
      return [];
    }
    const expandEntireField = expandOrCollapseFactory(params, true);
    const collapseEntireField = expandOrCollapseFactory(params, false);
    const defaultItems = params.defaultItems ?? [];

    return [
      {
        name: 'Expand Entire Field',
        icon: `<em class='icon icon-sm'>format_indent_increase</em>`,
        action: () => {
          expandEntireField();
        },
      },
      {
        name: 'Collapse Entire Field',
        icon: `<em class='icon icon-sm'>format_indent_decrease</em>`,
        action: () => {
          collapseEntireField();
        },
      },
      ...defaultItems,
    ];
  };
}
