import { strictEntries } from '@/utils/libs/entries.ts';

const measuresFromCache = ['Currency'] as const;
export type MeasureNameFromCache = (typeof measuresFromCache)[number];

export function isFromCache(measureId: AllMeasureId): measureId is MeasureNameFromCache {
  return measuresFromCache.find(m => m === measureId) !== undefined;
}

export type MeasureName =
  | 'UnderlyerSpot.Risk'
  | 'UnderlyerSpot.RT'
  | 'UnderlyerSpot.RT.Move'
  | 'Delta.Explained.RT.DisplayCurrency'
  | 'Delta.Explained.Draft.RT.DisplayCurrency'
  | 'Delta.Explained.RT'
  | 'Delta.ExcludingFixing'
  | 'NDelta.Move'
  | 'CrossGamma.Cross.RT'
  | 'CrossGamma.Diag.RT'
  | 'Delta.DisplayCurrency'
  | 'Delta'
  | 'Gamma.RT.DisplayCurrency'
  | 'Vega.RT.DisplayCurrency'
  | 'Theta.RT.DisplayCurrency'
  | 'PnlTotalE'
  | 'PnL.Delta.DisplayCurrency'
  | 'PnL.Gamma.Open.DisplayCurrency'
  | 'UnderlyerSpot.RT.Source'
  | 'UnderlyerSpot.RT.LastUpdate'
  | 'Delta.Jump.RT.DisplayCurrency'
  | 'Delta.Jump'
  | 'NDelta.Move.RT.DisplayCurrency'
  | 'CrossGamma.Cross.RT.DisplayCurrency'
  | 'CrossGamma.Diag.RT.DisplayCurrency'
  | 'Delta.ExcludingFixing.RT.DisplayCurrency'
  | 'TransGamma.DisplayCurrency'
  | 'Quantity'
  | 'Quantity.Daily'
  | 'Product.Quantity'
  | 'Product.All'
  | 'Product.Quantity.Daily'
  | 'Weight'
  | 'Underlyer.PointValue';

const measureById = {
  UnderlyerSpotRT: {
    name: 'UnderlyerSpot.RT',
    aggregation: 'AVG',
    valuationType: 'All',
    availableOnGreat: true,
    dailyOrOpen: 'All',
    type: 'price',
  },
  UnderlyerSpotRisk: {
    name: 'UnderlyerSpot.Risk',
    aggregation: 'AVG',
    valuationType: 'Intraday',
    availableOnGreat: true,
    dailyOrOpen: 'All',
    type: 'price',
  },
  UnderlyerSpotRTMove: {
    name: 'UnderlyerSpot.RT.Move',
    aggregation: 'AVG',
    valuationType: 'Official',
    availableOnGreat: true,
    dailyOrOpen: 'All',
    type: 'percentage',
  },
  DeltaCurrencyIntraday: {
    name: 'Delta.Explained.RT.DisplayCurrency',
    aggregation: 'SUM',
    valuationType: 'Intraday',
    availableOnGreat: true,
    dailyOrOpen: 'All',
    type: 'currency',
  },
  DeltaOpenRTIntraday: {
    name: 'Delta.Explained.RT',
    aggregation: 'SUM',
    valuationType: 'Intraday',
    availableOnGreat: true,
    dailyOrOpen: 'All',
    type: 'quantity',
  },
  NDeltaOpenMove: {
    name: 'NDelta.Move',
    aggregation: 'SUM',
    valuationType: 'Intraday',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'quantity',
  },
  XGamma: {
    name: 'CrossGamma.Cross.RT',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'quantity',
  },
  XGammaDiag: {
    name: 'CrossGamma.Diag.RT',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'quantity',
  },
  DeltaJumpOpenCurrency: {
    name: 'Delta.Jump.RT.DisplayCurrency',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'currency',
  },
  DeltaJumpOpen: {
    name: 'Delta.Jump',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'quantity',
  },
  NDeltaOpenMoveRTDisplayCurrency: {
    name: 'NDelta.Move.RT.DisplayCurrency',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'currency',
  },
  CrossGammaCrossOpenRTDisplayCurrency: {
    name: 'CrossGamma.Cross.RT.DisplayCurrency',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'currency',
  },
  CrossGammaDiagOpenRTDisplayCurrency: {
    name: 'CrossGamma.Diag.RT.DisplayCurrency',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'currency',
  },
  DeltaExcludingFixingOpenRTDisplayCurrency: {
    name: 'Delta.ExcludingFixing.RT.DisplayCurrency',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'currency',
  },
  DeltaOpenIntraday: {
    name: 'Delta.ExcludingFixing',
    aggregation: 'SUM',
    valuationType: 'Intraday',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'quantity',
  },
  Spot0: {
    name: 'UnderlyerSpot.Risk',
    aggregation: 'AVG',
    availableOnGreat: true,
    valuationType: 'Official',
    dailyOrOpen: 'All',
    type: 'price',
  },
  DeltaCurrencyOfficial: {
    name: 'Delta.DisplayCurrency',
    aggregation: 'SUM',
    availableOnGreat: true,
    valuationType: 'Official',
    dailyOrOpen: 'Open',
    type: 'currency',
  },
  DeltaOpenOfficial: {
    name: 'Delta',
    aggregation: 'SUM',
    availableOnGreat: true,
    valuationType: 'Official',
    dailyOrOpen: 'Open',
    type: 'quantity',
  },
  DeltaDailyCurrency: {
    name: 'Delta.Explained.RT.DisplayCurrency',
    aggregation: 'SUM',
    valuationType: 'Intraday',
    availableOnGreat: true,
    dailyOrOpen: 'Daily',
    type: 'currency',
  },
  DeltaDraftDailyCurrency: {
    name: 'Delta.Explained.Draft.RT.DisplayCurrency',
    aggregation: 'SUM',
    valuationType: 'Intraday',
    availableOnGreat: true,
    dailyOrOpen: 'Daily',
    type: 'currency',
  },
  DeltaDaily: {
    name: 'Delta.Explained.RT',
    aggregation: 'SUM',
    valuationType: 'Intraday',
    availableOnGreat: true,
    dailyOrOpen: 'Daily',
    type: 'quantity',
  },

  Gamma: {
    name: 'Gamma.RT.DisplayCurrency',
    aggregation: 'SUM',
    valuationType: 'Intraday',
    availableOnGreat: true,
    dailyOrOpen: 'All',
    type: 'currency',
  },
  GammaDaily: {
    name: 'Gamma.RT.DisplayCurrency',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Daily',
    type: 'currency',
  },
  GammaOpen: {
    name: 'Gamma.RT.DisplayCurrency',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'currency',
  },
  Vega: {
    name: 'Vega.RT.DisplayCurrency',
    aggregation: 'SUM',
    valuationType: 'Intraday',
    availableOnGreat: true,
    dailyOrOpen: 'All',
    type: 'currency',
  },
  VegaDaily: {
    name: 'Vega.RT.DisplayCurrency',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Daily',
    type: 'currency',
  },
  VegaOpen: {
    name: 'Vega.RT.DisplayCurrency',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'currency',
  },
  Theta: {
    name: 'Theta.RT.DisplayCurrency',
    aggregation: 'SUM',
    valuationType: 'Intraday',
    availableOnGreat: true,
    dailyOrOpen: 'All',
    type: 'currency',
  },
  ThetaDaily: {
    name: 'Theta.RT.DisplayCurrency',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Daily',
    type: 'currency',
  },
  ThetaOpen: {
    name: 'Theta.RT.DisplayCurrency',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'currency',
  },

  PnlTotalE: {
    name: 'PnlTotalE',
    valuationType: 'All',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'All',
    type: 'currency',
  },
  Pnl0E: {
    name: 'PnL.Delta.DisplayCurrency',
    valuationType: 'Official',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'currency',
  },
  PnlThetaE: {
    name: 'Theta.RT.DisplayCurrency',
    valuationType: 'Official',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'currency',
  },
  PnlGammaE: {
    name: 'PnL.Gamma.Open.DisplayCurrency',
    valuationType: 'All',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'All',
    type: 'currency',
  },
  PnlDeltaDayE: {
    name: 'PnL.Delta.DisplayCurrency',
    valuationType: 'Official',
    aggregation: 'SUM',
    availableOnGreat: false,
    dailyOrOpen: 'Daily',
    type: 'currency',
  },
  SpotSource: {
    name: 'UnderlyerSpot.RT.Source',
    valuationType: 'All',
    availableOnGreat: true,
    aggregation: 'AVG',
    isRequired: true,
    dailyOrOpen: 'All',
    type: 'text',
  },
  SpotUpdate: {
    name: 'UnderlyerSpot.RT.LastUpdate',
    valuationType: 'All',
    availableOnGreat: false,
    aggregation: 'AVG',
    dailyOrOpen: 'Open',
    type: 'text',
  },
  CrossGammaTransDisplayCurrency: {
    name: 'TransGamma.DisplayCurrency',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'All',
    type: 'currency',
  },
  CrossGammaTransOpenDisplayCurrency: {
    name: 'TransGamma.DisplayCurrency',
    valuationType: 'Intraday',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'currency',
  },
  CrossGammaTransDailyDisplayCurrency: {
    name: 'TransGamma.DisplayCurrency',
    valuationType: 'All',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Daily',
    type: 'currency',
  },
  ProductQuantity: {
    name: 'Product.Quantity',
    valuationType: 'All',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'All',
    type: 'quantity',
  },
  ProductQuantityOpen: {
    name: 'Product.Quantity',
    valuationType: 'All',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Open',
    type: 'quantity',
  },
  ProductQuantityDaily: {
    name: 'Product.Quantity',
    valuationType: 'All',
    aggregation: 'SUM',
    availableOnGreat: true,
    dailyOrOpen: 'Daily',
    type: 'quantity',
  },
} as const satisfies Record<string, Measure>;

export type MeasureId = keyof typeof measureById;
export type AllMeasureId = keyof typeof measureById | MeasureNameFromCache;
export type MeasureAggregation = 'SUM' | 'AVG' | 'MAX' | 'MIN' | 'stdDevNonBiased' | 'COUNT';

export function getAllMeasures(): Measure[] {
  return Object.values(measureById);
}

export function getAllMeasuresIncludingCache(): AllMeasureId[] {
  return [...getAllMeasureIds(), 'Currency'];
}

export function getAllMeasureIds(): MeasureId[] {
  return Object.keys(measureById) as MeasureId[];
}

export function getRequiredMeasureIds(): MeasureId[] {
  return strictEntries(measureById)
    .filter(([_, measure]: [string, Measure]) => measure.isRequired === true)
    .map(entry => entry[0]);
}

export const valuationTypes = ['Intraday', 'Official', 'All'] as const;
export type ValuationType = (typeof valuationTypes)[number];

export const dailyOrOpens = ['Open', 'Daily', 'All'] as const;
export type DailyOrOpen = (typeof dailyOrOpens)[number];

export type MeasureType = 'currency' | 'percentage' | 'price' | 'text' | 'quantity';

export interface Measure {
  name: MeasureName;
  availableOnGreat: boolean;
  valuationType: ValuationType;
  dailyOrOpen: DailyOrOpen;
  aggregation?: MeasureAggregation;
  isRequired?: boolean;
  type: MeasureType;
}

export function getMeasureById(measureId: MeasureId): Measure {
  const measure = measureById[measureId];
  if (measure === undefined) {
    throw new Error(`Could not find measure with id ${measureId}`);
  }
  return measure;
}

export function getMeasureType(measureId: AllMeasureId): MeasureType {
  if (isFromCache(measureId)) {
    return 'text';
  }
  return getMeasureById(measureId).type;
}
