import './index.css';
import 'allotment/dist/style.css';

import type { SgwtConnectHTMLElement } from './types/sgwt-widgets.ts';
import type { SGWTConnectCore, SGWTConnectError } from '@sgwt/connect-core';

import { renderApp } from '@/renderApp.tsx';
import { setupAgGridLicence } from '@/utils/libs/ag-grid.ts';
import { sgwtConnect } from '@/utils/sgwt/sgwtConnect.ts';

setupAgGridLicence();

if (import.meta.env.VITE_USE_SG_CONNECT === 'false') {
  renderApp();
} else {
  setupSgwtConnectWidget(sgwtConnect);

  if (sgwtConnect.isAuthorized()) {
    enableMocking().then(() => {
      renderApp();
    });
  } else {
    const authorizationError = sgwtConnect.getAuthorizationError();
    if (authorizationError !== null) {
      renderError(authorizationError);
    } else {
      sgwtConnect.requestAuthorization();
    }
  }
}

function renderError(authorizationError: SGWTConnectError) {
  document.body.innerHTML = `
      <div class='alert alert-danger' role='alert'>
        Authorization error: ${authorizationError}.
      </div>`;
}

function setupSgwtConnectWidget(sgwtConnect: SGWTConnectCore) {
  const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');

  if (widget) {
    // When the code is executed, the sgwtConnectWidget may not have been initialized.
    // So, we need to check that, otherwise calling `sgwtConnectWidget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the sgwtConnectWidget is ready...
      const handleSgwtConnectReady = () => {
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
        widget.setSgwtConnectInstance(sgwtConnect);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}

async function enableMocking() {
  // enable in local development only for the moment
  const isDev = process.env.NODE_ENV === 'development';
  // const isDev = getConfig().environment === 'devci' || getConfig().environment === 'local';
  if (!isDev) {
    return;
  }

  const { worker } = await import('@/mock/browser');
  console.log('MSW');

  return worker.start({ quiet: true, onUnhandledRequest: 'bypass' });
}
