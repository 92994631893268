import { CreateBatchAdjustmentModal } from './createBatchAdjustmentModal';
import { useModal } from '@ebay/nice-modal-react';

import { useAppSelector } from '@/store/hooks.ts';
import { queryCacheSlice } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { CreateAdjustmentModal } from '@/components/adjustments/CreateAdjustmentModal.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';

export function AdjustmentActionButtons() {
  const createAdjustmentModal = useModal(CreateAdjustmentModal);
  const createBatchAdjustmentModal = useModal(CreateBatchAdjustmentModal);
  const portfolios = useAppSelector(queryCacheSlice.selectors.portfolios);

  async function openAdjustmentModal() {
    await createAdjustmentModal.show({
      portfolios,
    });
  }

  async function openBatchAdjustmentModal() {
    await createBatchAdjustmentModal.show();
  }

  return (
    <>
      <Button onClick={openBatchAdjustmentModal} className="btn-icon-end btn-lg text-nowrap">
        Add new batch <em className="icon">content_copy</em>
      </Button>
      <Button onClick={openAdjustmentModal} className="btn-icon-end btn-lg text-nowrap">
        Add new <em className="icon">add</em>
      </Button>
    </>
  );
}
