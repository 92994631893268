import type { CellClassRules, ColDef, RowNode, ValueParserParams } from '@ag-grid-community/core';
import { asSequence } from 'sequency';

import {
  rulesEntityTypes,
  rulesModels,
  underlyingTypes,
  type RuleCategory,
} from '@/store/api/rulesApi/rulesModels.ts';
import type { AnalyticalStructureRowData } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { CustomCheckboxWithLabel } from '@/components/rules/common/CustomCheckboxWithLabel.tsx';
import { getDropdown, getEntityNameDropdown } from '@/components/rules/getDropdown.ts';
import { parseBooleanOrBooleanLike } from '@/components/rules/parsers.ts';
import type { RuleRowData } from '@/components/rules/rowDataManager/BaseRuleRowDataManager.ts';
import { RuleModalRowDataManager } from '@/components/rules/rowDataManager/RuleModalRowDataManager.ts';

export function getModalColDefs(
  category: RuleCategory,
  metricValues: string[],
  onDelete: (rowId: number) => void,
  modalRowDataManager: RuleModalRowDataManager,
  analyticalStructures: AnalyticalStructureRowData[],
) {
  const allColumnName = rulesModels[category].map(({ columnName }) => columnName);
  const columnDefinitions = asSequence(allColumnName)
    .distinctBy(columnName => columnName)
    .toArray();

  const cellClassRules: CellClassRules = {
    'ag-cell-danger': params => {
      if (modalRowDataManager.isInitialGridState()) {
        return false;
      }

      return !modalRowDataManager.isValidValue(
        params.colDef.field,
        params.value,
        params.data['entityType'],
      );
    },
  };

  const colDefs: ColDef[] = [
    {
      field: 'metric',
      cellClassRules,
      ...getDropdown(metricValues),
    },
    ...columnDefinitions
      .filter(columnName => columnName !== 'exclude')
      .map(columnName => {
        const colDefInit = { field: columnName, cellClassRules };

        if (columnName === 'entityType') {
          return { ...colDefInit, ...getDropdown([...rulesEntityTypes]) };
        }
        if (columnName === 'entityName') {
          return { ...colDefInit, ...getEntityNameDropdown(analyticalStructures) };
        }
        if (columnName === 'toUnderlyingType' || columnName === 'underlyingType') {
          return { ...colDefInit, ...getDropdown([...underlyingTypes]) };
        }

        return colDefInit;
      }),
    {
      headerName: 'Delete',
      width: 100,
      cellClass: 'flex-center',
      editable: false,
      cellRenderer: ({ node }: { node: RowNode }) => (
        <Button
          disabled={modalRowDataManager.isInitialGridState()}
          onClick={() => onDelete(node.data.id!)}
          icon
          variant="danger"
          flat
        >
          <em className="icon icon-sm flex-center">delete_outline</em>
        </Button>
      ),
    },
  ];

  if (category === 'EXCLUSION') {
    colDefs.unshift({
      field: 'exclude',
      valueParser: (params: ValueParserParams) => parseBooleanOrBooleanLike(params.newValue),
      cellDataType: 'boolean',
      cellRenderer: CustomCheckboxWithLabel,
      cellRendererParams: { labelOnTrue: 'Excluded', labelOnFalse: 'Not excluded' },
      cellClassRules,
      editable: false,
    });
  }

  colDefs.unshift(
    {
      width: 45,
      colId: 'duplicatedWithExternal',
      cellRenderer: ({ data }: { data: RuleRowData }) => {
        const duplicatedKey = modalRowDataManager.isDuplicatedFromExternal(data);
        if (duplicatedKey) {
          return <em className="icon icon-sm">error_outline</em>;
        }
      },
      tooltipValueGetter: ({ data }) => {
        const duplicatedKey = modalRowDataManager.isDuplicatedFromExternal(data);
        if (duplicatedKey) {
          return 'This line duplicated and will override the existing one';
        }
      },
    },
    {
      width: 45,
      colId: 'duplicated',
      cellRenderer: ({ data }: { data: RuleRowData }) => {
        const duplicatedKey = modalRowDataManager.isDuplicated(data);
        if (duplicatedKey !== undefined) {
          return <em className="icon icon-sm">warning</em>;
        }
      },
      tooltipValueGetter: ({ data }) => {
        const duplicatedKey = modalRowDataManager.isDuplicated(data);
        if (duplicatedKey !== undefined) {
          return 'Line is duplicated';
        }
      },
    },
  );

  return colDefs;
}
