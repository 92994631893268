import { useCallback, useMemo } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { ColDef, RowNode } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { useModal } from '@ebay/nice-modal-react';
import { useLoaderData, useRouter } from '@tanstack/react-router';

import { useDeletePreTradeDealMutation } from '@/store/api/adjustmentsApi/adjustmentApi.ts';
import type { BlotterDeal } from '@/store/api/adjustmentsApi/adjustmentApiModels.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import { queryCacheSlice } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { addErrorToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { selectUserHasAnyPermission } from '@/store/slices/user/userSlice.ts';
import { AdjustmentActionButtons } from '@/components/adjustments/AdjustmentActionButtons.tsx';
import { ConfirmDeleteAdjustmentModal } from '@/components/adjustments/ConfirmDeleteAdjustmentModal';
import { EditAdjustmentModal } from '@/components/adjustments/EditAdjustmentModal.tsx';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { NoRows } from '@/components/common/utils/NoRowsOverlay.tsx';
import { ShowIf } from '@/components/common/utils/ShowIf.tsx';
import { logger } from '@/utils/libs/logger.ts';

export function Adjustments() {
  const { preTradeDeals } = useLoaderData({ from: '/adjustments/' });
  const router = useRouter();
  const dispatch = useAppDispatch();

  const isEditAllowed = useAppSelector(selectUserHasAnyPermission('editAdjustments'));
  const editAdjustmentModal = useModal(EditAdjustmentModal);
  const confirmDeleteModal = useModal(ConfirmDeleteAdjustmentModal);

  const [deletePreTradeDeal] = useDeletePreTradeDealMutation();
  const portfolios = useAppSelector(queryCacheSlice.selectors.portfolios);

  const onDelete = useCallback(
    async function onDelete(tradeReference: string) {
      await confirmDeleteModal.show();
      try {
        await deletePreTradeDeal(tradeReference).unwrap();
      } catch (e) {
        logger.logError(
          `Fail to delete deal with id ${tradeReference} {error_s}`,
          JSON.stringify(e),
        );
        dispatch(addErrorToastThunk(`Error trying to delete deal : ${tradeReference}`));
      }
    },
    [confirmDeleteModal, deletePreTradeDeal, dispatch],
  );

  const onUpdate = useCallback(
    async function onUpdate(tradeReference: string) {
      await editAdjustmentModal.show({
        tradeReference,
        portfolios,
      });
    },
    [editAdjustmentModal, portfolios],
  );

  const coldDefs = useMemo(
    () => getAdjustmentsColDef(onDelete, onUpdate, isEditAllowed),
    [onDelete, onUpdate, isEditAllowed],
  );

  return (
    <>
      <div className="d-flex flex-between px-4 py-2 ">
        <div className="display-4">Daily adjustments</div>
        <div className="d-flex gap-1">
          <Button className="btn-lg btn-icon-end" onClick={router.invalidate}>
            Refresh adjustments <em className="icon">refresh</em>
          </Button>
          <ShowIf condition={isEditAllowed}>
            <AdjustmentActionButtons />
          </ShowIf>
        </div>
      </div>
      <div className="w-100 h-100">
        <AgGridReact<BlotterDeal>
          className="ag-theme-alpine ag-theme-era"
          rowData={preTradeDeals}
          modules={[ClientSideRowModelModule, RichSelectModule]}
          noRowsOverlayComponent={NoRows}
          gridOptions={{
            suppressMenuHide: false,
          }}
          columnDefs={coldDefs}
        />
      </div>
    </>
  );
}

function getAdjustmentsColDef(
  onDelete: (id: string) => void,
  onUpdate: (id: string) => void,
  isEditAllowed: boolean,
) {
  const colDefs: ColDef<BlotterDeal>[] = [
    {
      field: 'productDescription',
      headerName: 'Underlying',
    },
    {
      field: 'sgSide',
    },
    {
      field: 'portfolio',
    },
    {
      field: 'productQuantity',
    },
    {
      field: 'productPrice',
    },
    {
      field: 'amount',
    },
    {
      field: 'creationUser',
      headerName: 'Trader',
    },
    {
      field: 'status',
    },
    {
      field: 'xOneReference',
      headerName: 'X-One Reference',
    },
  ];

  if (isEditAllowed) {
    colDefs.push({
      headerName: 'Action',
      width: 130,
      cellClass: 'flex-center',
      cellRenderer: ({ node }: { node: RowNode<BlotterDeal> }) => {
        const xOneReference = node.data?.xOneReference;
        const isDisabled = node.data?.status === 'Destroyed';
        return (
          <>
            <Button
              disabled={isDisabled}
              className="m-2"
              onClick={() => {
                if (xOneReference) {
                  onDelete(xOneReference);
                }
              }}
              icon
              variant="danger"
              flat
            >
              <em className="icon icon-sm flex-center">delete_outline</em>
            </Button>
            <Button
              disabled={isDisabled}
              className="m-2"
              onClick={() => {
                const xOneReference = node.data?.xOneReference;
                if (xOneReference) {
                  onUpdate(xOneReference);
                }
              }}
              icon
              flat
            >
              <em className="icon icon-sm flex-center">edit</em>
            </Button>
          </>
        );
      },
    });
  }
  return colDefs;
}
