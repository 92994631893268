import type { TradeWay } from '@/store/api/adjustmentsApi/adjustmentApiModels.ts';
import { capitalizeWord } from '@/utils/strings.ts';

export function parseTradeWay(value: string): TradeWay | undefined {
  const buyAliases = ['B', 'Buy'];
  const sellAliases = ['S', 'Sell'];

  const capitalizedValue = capitalizeWord(value);

  if (buyAliases.includes(capitalizedValue)) {
    return 'Buy';
  }

  if (sellAliases.includes(capitalizedValue)) {
    return 'Sell';
  }

  return undefined;
}