import { useId } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button } from '@/components/common/bootstrap/Button.tsx';
import { ShowIf } from '@/components/common/utils/ShowIf.tsx';
import type { IntlMessages } from '@/types/intl';

export interface ToggleButtonProps<T> {
  formatId?: IntlMessages;
  activeValue: T;
  values: readonly T[];
  onClick: (value: T) => void;
  formatter?: (value: T) => string;
  hide?: boolean;
  disabled?: boolean;
}
export function ToggleButton<T>({
  activeValue,
  values,
  formatter = (value: T) => `${value}`,
  formatId,
  onClick,
  hide = false,
  disabled = false,
}: ToggleButtonProps<T>) {
  const id = `${formatId}-${useId()}`;
  if (hide) {
    return null;
  }

  return (
    <div className="input-container">
      <ShowIf condition={formatId !== undefined}>
        <label htmlFor={id} className="form-label">
          <FormattedMessage id={formatId} />
        </label>
      </ShowIf>

      <div id={id} className="btn-group btn-group-toggle d-block">
        {values.map((value, index) => {
          return (
            <Button
              disabled={disabled}
              key={index}
              className={classNames({ active: activeValue === value }, 'btn-toggle-primary')}
              onClick={() => onClick(value)}
            >
              {formatter(value)}
            </Button>
          );
        })}
      </div>
    </div>
  );
}
