import { useCallback, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { useBookPreTradeDealMutation } from '@/store/api/adjustmentsApi/adjustmentApi.ts';
import {
  wayValues,
  type BookPreTradeDealRequest,
  type TradeWay,
} from '@/store/api/adjustmentsApi/adjustmentApiModels.ts';
import { useAppDispatch } from '@/store/hooks.ts';
import { addSuccessToastThunk } from '@/store/slices/ui/uiSlice.ts';
import { AutoCompleteCombo } from '@/components/common/bootstrap/AutoCompleteCombo.tsx';
import { BootstrapModal } from '@/components/common/bootstrap/BootstrapModal.tsx';
import { CancelConfirmFooter } from '@/components/common/bootstrap/CancelConfirmFooter.tsx';
import { ErrorPanel } from '@/components/common/bootstrap/ErrorPanel.tsx';
import { LoaderWithText } from '@/components/common/bootstrap/LoaderWithText.tsx';
import { ToggleButton } from '@/components/common/bootstrap/ToggleButton.tsx';
import { ShowIf } from '@/components/common/utils/ShowIf.tsx';
import { logger } from '@/utils/libs/logger.ts';

const defaultBookPreTradeDealRequest: BookPreTradeDealRequest = {
  underlying: '',
  way: 'Buy',
  portfolio: '',
  productQuantity: '' as unknown as number,
  productPrice: '' as unknown as number,
};

type CreateAdjustmentModalProps = { portfolios: string[] };
export const CreateAdjustmentModal = NiceModal.create(
  ({ portfolios }: CreateAdjustmentModalProps) => {
    const [bookPreTradeDealRequest, setBookPreTradeDealRequest] = useState<BookPreTradeDealRequest>(
      defaultBookPreTradeDealRequest,
    );
    const [bookPreTrade, { isLoading, isError, error }] = useBookPreTradeDealMutation();
    const dispatch = useAppDispatch();
    const modal = useModal();

    async function onConfirm() {
      try {
        await bookPreTrade(bookPreTradeDealRequest).unwrap();
        dispatch(
          addSuccessToastThunk('Adjustment successfully book in X-One', 'Single daily adjustment'),
        );
        modal.remove();
      } catch (e) {
        logger.logError(`Fail to book deal  {error_s}`, JSON.stringify(e));
      }
    }

    const onPortfolioChange = useCallback((portfolio: string) => {
      setBookPreTradeDealRequest(previousRequest => ({
        ...previousRequest,
        portfolio,
      }));
    }, []);

    return (
      <BootstrapModal
        titleId="Adjustment.AddNewModalTitle"
        footer={
          <CancelConfirmFooter
            onConfirm={onConfirm}
            removeOnConfirm={false}
            cancelButtonProps={{
              disabled: isLoading,
            }}
            confirmButtonProps={{
              disabled: isLoading,
              component: 'Book in X-One',
            }}
          >
            <ShowIf condition={isLoading}>
              <LoaderWithText text={'Booking in progress'} />
            </ShowIf>
          </CancelConfirmFooter>
        }
      >
        <>
          <div className="mb-3">
            <label htmlFor="Underlying" className="text-secondary form-label">
              Underlying
            </label>
            <input
              disabled={isLoading}
              onChange={e =>
                setBookPreTradeDealRequest(previousRequest => ({
                  ...previousRequest,
                  underlying: e.target.value,
                }))
              }
              className="form-control"
              type="text"
              value={bookPreTradeDealRequest.underlying}
            />
          </div>
          <div className="mb-3">
            <ToggleButton<TradeWay>
              disabled={isLoading}
              formatId="Adjustment.Way.Radio.Label"
              activeValue={bookPreTradeDealRequest.way}
              values={wayValues}
              onClick={value =>
                setBookPreTradeDealRequest(previousRequest => ({
                  ...previousRequest,
                  way: value,
                }))
              }
            />
          </div>
          <div className="mb-3">
            <label htmlFor="Portfolio" className="text-secondary form-label">
              Portfolio
            </label>
            <AutoCompleteCombo
              disabled={isLoading}
              allowCustomValue
              customValueToItem={value => `${value}`}
              initialItems={portfolios}
              onChange={onPortfolioChange}
              selectedItem={bookPreTradeDealRequest.portfolio}
              maxHeight={150}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="Price" className="text-secondary form-label">
              Price
            </label>
            <div>
              <input
                disabled={isLoading}
                onChange={e =>
                  setBookPreTradeDealRequest(previousRequest => ({
                    ...previousRequest,
                    productPrice: Number(e.target.value),
                  }))
                }
                className="form-control"
                type="number"
                value={bookPreTradeDealRequest.productPrice}
              />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="Price" className="text-secondary form-label">
              Quantity
            </label>
            <div>
              <input
                disabled={isLoading}
                onChange={e =>
                  setBookPreTradeDealRequest(previousRequest => ({
                    ...previousRequest,
                    productQuantity: Number(e.target.value),
                  }))
                }
                className="form-control"
                type="number"
                value={bookPreTradeDealRequest.productQuantity}
              />
            </div>
          </div>
          <ShowIf condition={isError}>
            <ErrorPanel className="mt-4">{JSON.stringify(error)}</ErrorPanel>
          </ShowIf>
        </>
      </BootstrapModal>
    );
  },
);
