import type { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import type { IntlMessages } from '@/types/intl';

type ErrorPanelProps = Partial<{
  messageId: IntlMessages;
  className: string;
}>;

export function ErrorPanel({ children, messageId, className }: PropsWithChildren<ErrorPanelProps>) {
  const classNames = `alert alert-danger ${className}`;
  return (
    <div className={classNames} role="alert">
      <h4 className="alert-heading">
        <FormattedMessage id="App.Error" />
      </h4>
      <p className="mb-0 overflow-wrap-break">
        {messageId === undefined ? children : <FormattedMessage id={messageId} />}
      </p>
    </div>
  );
}
