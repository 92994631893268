import type { CellClassParams, ColDef, NewValueParams, RowNode } from '@ag-grid-community/core';
import { asSequence } from 'sequency';

import {
  rulesEntityTypes,
  rulesModels,
  underlyingTypes,
  type RuleCategory,
} from '@/store/api/rulesApi/rulesModels.ts';
import type { AnalyticalStructureRowData } from '@/store/slices/queryCache/queryCacheSlice.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { CustomCheckboxWithLabel } from '@/components/rules/common/CustomCheckboxWithLabel.tsx';
import { getDropdown, getEntityNameDropdown } from '@/components/rules/getDropdown.ts';
import type { RuleRowData } from '@/components/rules/rowDataManager/BaseRuleRowDataManager.ts';
import { RowDataManager } from '@/components/rules/rowDataManager/RowDataManager.ts';
import type { TableMode } from '@/components/rules/RulesCategoryComponent.tsx';

export function getColDefs(
  category: RuleCategory,
  mode: TableMode,
  metricValues: string[],
  rowDataManager: RowDataManager,
  onDelete: (rowId: string) => void,
  analyticalStructures: AnalyticalStructureRowData[],
) {
  const allColumnName = rulesModels[category].map(({ columnName }) => columnName);
  const columnDefinitions = asSequence(allColumnName)
    .distinctBy(columnName => columnName)
    .toArray();

  function cellClass(params: CellClassParams) {
    const { value, column, node } = params;
    return rowDataManager.hasValueChanged(node.id, column.getId(), value) ? 'ag-cell-dirty' : '';
  }

  const colDefs: ColDef[] = [
    {
      field: 'metric',
      cellClass,
      ...getDropdown(metricValues),
    },
    ...columnDefinitions
      .filter(elem => elem !== 'exclude')
      .map(columnDefinition => {
        const colDefInit = { field: columnDefinition, cellClass };

        if (columnDefinition === 'entityType') {
          return { ...colDefInit, ...getDropdown([...rulesEntityTypes]) };
        }
        if (columnDefinition === 'entityName') {
          return { ...colDefInit, ...getEntityNameDropdown(analyticalStructures) };
        }
        if (columnDefinition === 'toUnderlyingType' || columnDefinition === 'underlyingType') {
          return { ...colDefInit, ...getDropdown([...underlyingTypes]) };
        }

        return { ...colDefInit, onCellValueChanged: refreshCell };
      }),
  ];

  if (category === 'EXCLUSION') {
    colDefs.unshift({
      field: 'exclude',
      cellClass,
      onCellValueChanged: refreshCell,
      cellRenderer: CustomCheckboxWithLabel,
      cellRendererParams: { mode, labelOnTrue: 'Excluded', labelOnFalse: 'Not excluded' },
      editable: false,
    });
  }

  if (mode === 'edit') {
    colDefs.push({
      headerName: 'Delete',
      width: 100,
      cellClass: 'flex-center',
      editable: false,
      cellRenderer: ({ node }: { node: RowNode }) => (
        <Button onClick={() => onDelete(node.id!)} icon variant="danger" flat>
          <em className="icon icon-sm flex-center">delete_outline</em>
        </Button>
      ),
    });
    colDefs.unshift({
      width: 40,
      colId: 'duplicated',
      cellRenderer: ({ data }: { data: RuleRowData }) => {
        const duplicatedKey = rowDataManager.isDuplicated(data);
        if (duplicatedKey) {
          return <em className="icon icon-sm">warning</em>;
        }
      },
      tooltipValueGetter: ({ data }) => {
        const duplicatedKey = rowDataManager.isDuplicated(data);
        if (duplicatedKey !== undefined) {
          return 'Line is duplicated';
        }
      },
    });
  }

  return colDefs;
}
export function refreshCell({ node, api, column }: NewValueParams) {
  if (node == null) {
    return;
  }

  api.refreshCells({
    columns: [column.getId()],
    rowNodes: [node],
    force: true,
  });
}
